import * as React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

type Props = {}

// Analytics и прочие палевные название режутся адблоком в дев режиме
const Counters: React.FC<Props> = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if(window.ym && window?.Ya?._metrika.counters) {
      Object.keys(Ya._metrika.counters).forEach(id => window.ym(id, 'hit', pathname))
    } else if (window.ym && window.YM_ID) {
      window.ym(window.YM_ID, 'hit', pathname);
    }
    window.GTAG_ID && window?.gtag('config', window.GTAG_ID, {
      page_path: location.pathname,
    });
  }, [pathname]);

  return null;
};

export default Counters;
