import React from "react";
import { TVariablesReact, useReactTranslation } from "src/i18n";

type Props = {
  i18nKey: string,
  tags?: Record<string, (text: string) => React.ReactNode>
  variables?: TVariablesReact,
}

const Trans: React.FC<Props> = (props) => {
  const { i18nKey, tags, variables } = props;
  const { t } = useReactTranslation();

  return React.createElement(React.Fragment, { children: t(i18nKey, variables, tags) });
};

export default React.memo(Trans);