import { Locale as DFNSLocale } from "date-fns";
import { enUS, es, kk, ptBR, ru } from "date-fns/locale";
import { enUS as rsEN, esES as rsEs, Locale as RSLocale, ptBR as rsPtBR, ruRU as rsRu } from "rsuite/locales";
import rsKK from "./kk_KZ";
import {
  ruRU as muiRuRU,
  enUS as muiEnUS,
  esES as muiEsES,
  ptBR as muiPtBR,
  kkKZ as muiKkKz,
  Localization,
} from "@mui/material/locale";

export const mapLangToDateFnsLocale: Record<string, DFNSLocale> = {
  "en":    enUS,
  "pt-BR": ptBR,
  "pt-br": ptBR,
  ru,
  es,
  kk
};

export const mapLangToRsuiteLocale: Record<string, RSLocale> = {
  "ru":    rsRu,
  "en":    rsEN,
  "es":    rsEs,
  "pt-BR": rsPtBR,
  "pt-br": rsPtBR,
  "kk":    rsKK
};

export const mapLangToMuiLocale: Record<string, Localization> = {
  "ru": muiRuRU,
  "en": muiEnUS,
  "es": muiEsES,
  "pt-BR": muiPtBR,
  "pt-br": muiPtBR,
  "kk": muiKkKz,
};

export const langs = [
  {
    text:  "Русский",
    value: "ru"
  },
  {
    text:  "English",
    value: "en"
  },
  {
    text:  "Español",
    value: "es"
  },
  {
    text:  "Português (Brasileiro)",
    value: "pt-br"
  },
  {
    text:  "Қазақ",
    value: "kk"
  }
];

export const I18N_DEFAULT_WORKSPACE = 'workspace';

export const I18N_EVENT = 'change';

export enum I18n_namespace {
  'workspace' = 'workspace',
  'auth' = 'auth',
  'clients' = 'clients',
  'companies' = 'companies',
  'chat' = 'chat',
  'ticket' = 'ticket',
  'menu' = 'menu',
  'editor' = 'editor',
}