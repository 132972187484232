import React from "react";
import styles from "./styles.module.scss";
import { langs, toggleDebug } from "src/i18n";
import { updateLanguage } from "src/i18n";

type Props = {}

const I18nToggle: React.FC<Props> = () => (
  <div className={styles.container}>
    <div className={styles.title}>l18n</div>
    <button
      onClick={toggleDebug}
      className={styles.button}
    >
      mode
    </button>
    {langs.map((lang) => (
      <button key={lang.value} onClick={() => updateLanguage(lang.value)}>{lang.value}</button>
    ))}
  </div>
);

export default React.memo(I18nToggle);
