import { request } from "../base";

export class SettingsAPI {
  static async getSentryConfig() {
    return await request<ISentryConfig>({
      url: '/internal/api/sentry',
      method: 'GET',
      cache: {
        ttl: 30 * 1000,
      },
    })
  }
}

interface ISentryConfig {
  data: {
    enabled?: boolean;
    dsn?: string;
    environment?: string;
  }
}