import LanguageDetector from "i18next-browser-languagedetector";
import EventEmitter from "src/shared/EventEmitter";
import { I18N_EVENT } from "./constants";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: "domain",
  lookup() {
    const domainZone =
      typeof window !== "undefined"
        ? new URL(window.location.href).hostname.split(".").slice(-1)[0]
        : "";
    switch (domainZone) {
      case "ru":
      case "local":
        return "ru";
      case "com":
        return "en";
      case "io":
        return "pt-br";
      default:
        return undefined;
    }
  }
});
languageDetector.addDetector({
  name: "backend",
  lookup() {
    return typeof window !== "undefined" ? window.AGENT?.lang : undefined;
  }
});
languageDetector.init({
  languageUtils: {}
}, {
  lookupQuerystring: "lang",
  order:             ["backend", "localStorage", "cookie", "querystring", "domain", "navigator", "htmlTag"],
  caches:            ["localStorage", "cookie"]
});

const emitter = new EventEmitter();
let lng = (languageDetector.detect() as string).toLowerCase();

export const updateLanguage = (newLng: string) => {
  if (lng === newLng || typeof newLng !== "string") return;
  lng = newLng.toLowerCase();
  languageDetector.cacheUserLanguage(lng);
  emitter.emit(I18N_EVENT, { lng });
};

export const getCurrentLanguage = () => lng;

export const onChangeLanguage = (cb: (data: { lng: string }) => any) => emitter.on(I18N_EVENT, cb);

(window as any).updateLanguage = updateLanguage;
