import axios  from "axios";
import qs from "qs";
import { CacheRequestConfig, setupCache } from "axios-cache-interceptor";

const DEFAULT_CACHE_TTL = 60 * 1000;
const axiosInstance = axios.create();
axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
if(axiosInstance.defaults.paramsSerializer) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axiosInstance.defaults.paramsSerializer.encode = (params: any) => qs.stringify(params, { encode: false });
} else {
  axiosInstance.defaults.paramsSerializer = {
    serialize: (params) => qs.stringify(params, { encode: false })
  };
}

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';

const cachedInstance = setupCache(axiosInstance, {
  ttl: DEFAULT_CACHE_TTL,
  interpretHeader: false,
})

export const request = <T>(config: CacheRequestConfig) => {
  return cachedInstance.request<T>({
    ...config,
    ...(config.cache ? {
      cache: {
        ...config.cache,
        interpretHeader: false,
      },
    } : {
      cache: {
        interpretHeader: true,
      }
    })
  });
};