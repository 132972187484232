import * as React from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import { arrayToIdIndex } from "../../../shared/arrayToIdIndex";
import { RouteItem, routes } from "../../../routes";
import RootErrorBoundary from "../components/RootErrorBoundary";
import Root from "../components/Root";
import RouteWrapper from "../components/RouteWrapper";

export const finalRoutes: RouteObject[] = routes.map(route => ({
  id: route.name,
  path: route.path,
  element: <RouteWrapper route={route} />,
  errorElement: <RootErrorBoundary />,
}));

export const router = createBrowserRouter([{
  path: "/",
  element: <Root />,
  errorElement: <RootErrorBoundary />,
  children: finalRoutes,
}], { basename: "/" });

export const routesDict = arrayToIdIndex(routes, "name");
export type RouteName = keyof typeof routesDict;

const componentsCache: Record<string, React.LazyExoticComponent<React.FC>> = {};
const layoutsCache: Map<RouteItem["layout"], React.LazyExoticComponent<React.FC<React.PropsWithChildren>>> = new Map();

export const getCachedComponent = (route: RouteItem) => {
  if (!componentsCache[route.path]) {
    componentsCache[route.path] = React.lazy(route.component);
  }
  return componentsCache[route.path];
};

export const getCachedLayout = (route: RouteItem) => {
  if (!layoutsCache.get(route.layout)) {
    layoutsCache.set(route.layout, React.lazy(route.layout));
  }
  return layoutsCache.get(route.layout);
};
