import * as React from "react";

export type RouteItem = Readonly<{
  path: string,
  name: string,
  component: () => Promise<{ default: React.FC }>,
  layout: () => Promise<{ default: React.FC<React.PropsWithChildren> }>,
}>;

const AuthLayout = () => import("./pages/auth/AuthProvider");
const BaseLayout = () => import("./components/Layouts/BaseLayout");

export const routes = [
  {
    path: "/workspace",
    name: "ticketsList",
    component: () => import("./pages/workspace"),
    layout: BaseLayout,
  },
  {
    path: "/clients",
    name: "clientsList",
    component: () => import("./pages/clients/list"),
    layout: BaseLayout,
  },
  {
    path: "/clients/details/:id",
    name: "client",
    component: () => import("./pages/clients/detail"),
    layout: BaseLayout,
  },
  {
    path: "/clients/companies",
    name: "companiesList",
    component: () => import("./pages/companies/list"),
    layout: BaseLayout,
  },
  {
    path: "/clients/companies/:id",
    name: "company",
    component: () => import("./pages/companies/detail"),
    layout: BaseLayout,
  },
  {
    path: "/settings/users",
    name: "settingsUsers",
    component: () => import("./pages/agents/list"),
    layout: BaseLayout,
  },
  {
    path: "/settings/user-groups",
    name: "settingsUserGroups",
    component: () => import("./pages/agents/groups"),
    layout: BaseLayout,
  },
  {
    path: "/tickets/:id",
    name: "ticket",
    component: () => import("./pages/tickets/detail"),
    layout: BaseLayout,
  },
  {
    path: "/chat",
    name: "chatsList",
    component: () => import("./pages/chat"),
    layout: BaseLayout,
  },
  {
    path: "/chat/:id",
    name: "chat",
    component: () => import("./pages/chat"),
    layout: BaseLayout,
  },

  {
    path: "/azure/login",
    name: "azureLogin",
    component: () => import("./pages/auth/azure/login"),
    layout: AuthLayout,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/auth/login"),
    layout: AuthLayout,
  },
  {
    path: "/login/google-auth",
    name: "googleAuth",
    component: () => import("./pages/auth/login/google-auth"),
    layout: AuthLayout,
  },
  {
    path: "/login/sms",
    name: "smsAuth",
    component: () => import("./pages/auth/login/sms"),
    layout: AuthLayout,
  },
  {
    path: "/password-restore",
    name: "passwordRestore",
    component: () => import("./pages/auth/password-restore"),
    layout: AuthLayout,
  },
  {
    path: "/password-restore/:hash",
    name: "passwordRestoreConfirm",
    component: () => import("./pages/auth/password-restore/[hash]"),
    layout: AuthLayout,
  },
  {
    path: "/settings/update-password",
    name: "changePassword",
    component: () => import("./pages/auth/settings/update-password"),
    layout: AuthLayout,
  },
  {
    path: "/signup",
    name: "register",
    component: () => import("./pages/auth/signup"),
    layout: AuthLayout,
  },
  {
    path: "/signup/confirm-email",
    name: "confirmEmail",
    component: () => import("./pages/auth/signup/confirm-email"),
    layout: AuthLayout,
  },
  {
    path: "/signup/settings",
    name: "registerSettings",
    component: () => import("./pages/auth/signup/settings"),
    layout: AuthLayout,
  },
  {
    path: "/signup/success",
    name: "registerSuccess",
    component: () => import("./pages/auth/signup/success"),
    layout: AuthLayout,
  },
] as const satisfies readonly RouteItem[];


