import * as React from "react";
import { SettingsAPI } from "../../../../stores/api/common/settings";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

type Props = {};

const Sentry: React.FC<Props> = () => {
  React.useEffect(() => {
    Promise.all([
      import("@sentry/react"),
      SettingsAPI.getSentryConfig(),
    ]).then(
      ([Sentry, result]) => {
        const { data: { enabled, dsn, environment } } = result.data;
        if (!enabled || !dsn || !environment) return;
        Sentry.init({
          dsn,
          environment,
          tracesSampleRate: 0.1,
          transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
              useEffect: React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            }),
          ],
        });
      },
      e => console.error("Cannot get sentry config", e),
    );
  }, []);

  return null;
};

export default React.memo(Sentry);