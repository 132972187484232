import * as React from "react";
import { Outlet } from "react-router-dom";
import Toasts from "../../../common/Toasts";
import { StyledEngineProvider } from "@mui/material/styles";
import Counters from "../Counters";
import Sentry from "../Sentry";
import { isDev } from "src/i18n";
import I18nToggle from "src/components/common/I18nToggle/I18nToggle";

type Props = {};

const Root: React.FC<Props> = () => {
  return (
    <>
      <Sentry />
      <Counters />
      <Toasts />
      {isDev && <I18nToggle />}
      <StyledEngineProvider injectFirst>
        <Outlet />
      </StyledEngineProvider>
    </>
  );
};

export default React.memo(Root);
