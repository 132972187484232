import * as React from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RootErrorBoundary: React.FC = () => {
  const error = useRouteError();
  const { t } = useTranslation();

  return <div>{t('workspace:common_errors.js_error')}</div>;
};

export default React.memo(RootErrorBoundary);
