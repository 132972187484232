import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { router } from "./helpers/router";
import Loader from "../common/Loader";

const App = () => {
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <RouterProvider
          router={router}
          future={{ v7_startTransition: true }}
          fallbackElement={<Loader />}
        />
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default App;
