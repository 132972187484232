export type EventEmitterCallback<D = any> = (data?: D, eventName?: string) => any;

export type EventEmitterListener = () => void;

export type EventEmitterEvents = {
  [event: string]: Array<EventEmitterCallback>;
}

export default class EventEmitter {
  events: EventEmitterEvents = {};

  on(event: string, callback: EventEmitterCallback): EventEmitterListener {
    if (typeof this.events[event] !== "object") this.events[event] = [];
    this.events[event].push(callback);
    return () => {
      const index = this.events[event].indexOf(callback);
      this.events[event].splice(index, 1);
    };
  };

  emit(event: string, data?: any): void {
    const events = this.events[event];
    if (!events) return;
    for (let i = 0; i < events.length; i++) {
      events[i](data, event);
    }
  }
}
