import { setDefaultOptions } from "date-fns";
import { updateLanguage, onChangeLanguage, getCurrentLanguage } from "./language";
import { mapLangToDateFnsLocale } from "./constants";
import * as React from "react";

export { langs, mapLangToRsuiteLocale, mapLangToDateFnsLocale, mapLangToMuiLocale, I18n_namespace } from "./constants";
export { default as Trans } from "./Trans";
export { useTranslation, useReactTranslation } from "./useTranslation";
export { useLanguage } from "./useLanguage";
export { usePreloadTranslation } from "./usePreloadTranslation";
export { toggleDebug, isDev } from "./debug";
export { updateLanguage, getCurrentLanguage } from "./language";

export type TVariables = Record<string, string | number>
export type TVariablesReact = Record<string, React.ReactNode>
export type TTags = Record<string, (text: string) => React.ReactNode>

export type TFunction = (key: string, variables?: TVariables) => string;
export type TReactFunction = (key: string, variables?: TVariablesReact, tags?: TTags) => React.ReactNode[];

const updateDateFns = () => setDefaultOptions({
  locale: mapLangToDateFnsLocale[getCurrentLanguage()],
  weekStartsOn: 1,
});
onChangeLanguage(updateDateFns);
updateDateFns();

(window as any).updateLanguage = updateLanguage;