import { ToastContainer, toast } from "react-toastify";
import styles from "./styles.module.scss";
import { TFunction, useTranslation } from "src/i18n";

let toastFunc: typeof toast;
let t: TFunction;

const ToastsComponent = () => {
  toastFunc = toast;
  t = useTranslation().t;
  return <ToastContainer />;
};

export const useToasts = () => ({
  toast: toastFunc,
  showError: (msg = "common_errors.error_occured") => toastFunc<string>(t(msg), { type: "error", className: styles.error_toast }),
  showInfo: (msg: string) => toastFunc<string>(t(msg), { type: "info" }),
});
export default ToastsComponent;