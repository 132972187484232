import EventEmitter from "src/shared/EventEmitter";
import { getCurrentLanguage, onChangeLanguage } from "./language";
import { I18N_EVENT } from "./constants";

type Namespace = {
  name: string,
  lng: string,
  data: null | object,
  error: null | Error,
  promise: Promise<any>
};

const namespaces: Record<string, Record<string, Namespace>> = {};
const lastLoadedLangs: Record<string, string> = {};
const emitter = new EventEmitter();

const updateLastLoadedLang = (name: string, lng: string) => {
  if (lng === getCurrentLanguage()) lastLoadedLangs[name] = lng;
};

onChangeLanguage(({ lng }) => {
  for (const name in namespaces) {
    const namespace = getNamespace(name, true);
    if (namespace.data) {
      updateLastLoadedLang(name, lng);
      emitter.emit(I18N_EVENT, { name, lng });
    }
  }
});

const getChunk = (name: string, lng: string) => {
  return fetch(`/assets/lang/workspace/${name}.${lng}.json`)
    .then((resp) => resp.json());
};

export const getNamespace = (name: string, withoutFallback?: boolean) => {
  const lng = getCurrentLanguage();
  if (!namespaces[name]) namespaces[name] = {};
  if (!namespaces[name][lng]) {
    const promise = getChunk(name, lng).then(
      (data) => {
        namespaces[name][lng].data = data;
        updateLastLoadedLang(name, lng);
        emitter.emit(I18N_EVENT, { name, lng });
      },
      (error) => {
        namespaces[name][lng].error = error;
        emitter.emit(I18N_EVENT, { name, lng });
      }
    );
    namespaces[name][lng] = {
      name,
      lng,
      data:  null,
      error: null,
      promise
    };
  }
  let namespace = namespaces[name][lng];
  if (!withoutFallback && !namespace.data && lastLoadedLangs[name]) namespace = namespaces[name][lastLoadedLangs[name]];
  return namespace;
};

export const onLoadNamespace = (cb: (data: { name: string, lng: string }) => any) => emitter.on(I18N_EVENT, cb);

