import * as React from "react";
import _isNil from "lodash/isNil";
import _isObjectLike from "lodash/isObjectLike";
import { getNamespace } from "./namespaces";
import { I18N_DEFAULT_WORKSPACE } from "./constants";
import { getDebug, isDev } from "./debug";
import { TTags, TVariables, TVariablesReact } from "src/i18n/index";

const parsedKeys: Record<string, { ns: string, key: string, getter: string[] }> = {};

export const parseKey = (key: string) => {
  if (!parsedKeys[key]) {
    let [ns, localKey] = key.split(":");
    if (!localKey) {
      localKey = ns;
      ns = I18N_DEFAULT_WORKSPACE;
    }
    parsedKeys[key] = {
      ns,
      key: localKey,
      getter: localKey.split("."),
    };
  }
  return parsedKeys[key];
};

export function getTranslate(key: string, variables?: TVariables): string {
  const debug = getDebug();
  if (isDev && debug === "on") return `[${key}]`;

  let value = getKeyValue(key);

  if (_isObjectLike(variables)) {
    for (const key in variables) {
      value = value.replace(`{{${key}}}`, String(variables[key]));
    }
  }

  if (isDev && debug === "combine") return `[${key}] ${value}`;
  return value;
}

export const getReactTranslate = (key: string, variables?: TVariablesReact, tags?: TTags): React.ReactNode[] => {
  const debug = getDebug();
  if (isDev && debug === "on") return [`[${key}]`];

  const keyValue = getKeyValue(key);
  const value: React.ReactNode[] = [];

  let lastPosition = 0;
  const matches = keyValue.matchAll(/{{(.*?)}}|<([^>]+?)>(.*?)<\/\2>/g); // извлекаются все <0></0> и {{ }}
  for (const match of matches) {
    let result: React.ReactNode;
    const matchLength = match[0]?.length || 0;
    if (match[1]) {
      // ['{{var}}', 'var']
      const [source, variable] = match;
      result = variables?.[variable] ? variables?.[variable] : source;
    } else if (match[2]) {
      // ['<0>tag</0>', undefined, '0', 'tag']
      const [source, , tag, content] = match;
      result = tags?.[tag] ? tags[tag](content) : source;
    } else {
      result = "";
    }

    const startPosition = (match.index || 0);
    const finalPosition = lastPosition + startPosition + matchLength;
    value.push(keyValue.slice(lastPosition, startPosition));
    lastPosition = finalPosition;
    value.push(result);
  }

  if (lastPosition !== keyValue.length) {
    value.push(keyValue.slice(lastPosition));
  }

  if (isDev && debug === "combine") value.unshift(`[${key}] ${value}`);
  return value;
};

const getKeyValue = (key: string): string => {
  const parsedKey = parsedKeys[key];
  const { ns, getter } = parsedKey;
  const namespace = getNamespace(ns);
  if (namespace.error) return key;
  if (!namespace.data) return "";
  let path: any = namespace.data;
  for (let i = 0; i < getter.length; i++) {
    if (!path) break;
    path = path[getter[i]];
  }

  let value = path as string;

  if (_isNil(value)) {
    value = key;
    // if (isDev) console.warn("Не найден ключ перевода: ", value);
  }

  return String(value);
};
