import * as React from "react";
import { RouteItem } from "../../../../routes";
import { getCachedComponent, getCachedLayout } from "../../helpers/router";
import Loader from "../../../common/Loader";
import styles from "./RouteWrapper.module.scss";

type Props = {
  route: RouteItem
};

const RouteWrapper: React.FC<Props> = (props) => {
  const { route } = props;

  const Component = getCachedComponent(route);
  const Layout = getCachedLayout(route);

  if (!Layout) {
    console.error("Не задан layout для роута", route);
    return null;
  }
  return (
    <React.Suspense fallback={<Loader className={styles.loader} />}>
      <Layout>
        <React.Suspense fallback={<Loader className={styles.loader} />}>
          <Component />
        </React.Suspense>
      </Layout>
    </React.Suspense>
  );
};

export default React.memo(RouteWrapper);
