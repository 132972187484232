import * as React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

const variants = {
  primary: "#3D79F2",
  secondary: "#FFFFFF"
}

const syncAnimation = (e: React.AnimationEvent<HTMLElement | SVGElement>) => {
  const [animation] = e.currentTarget.getAnimations();
  if (animation) animation.startTime = 0;
}

type Props = {
  className?: string,
  size?: number,
  variant?: "primary" | "secondary"
}

const Loader: React.FC<Props> = ({
  className,
  size = 48,
  variant = "primary",
}) => (
  <span className={className}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.loader}
      data-testid="loader"
      onAnimationStart={syncAnimation}
    >
      <path id="Vector" d="M34.1513 55.2985C37.8744 54.6876 41.4347 53.2592 44.5676 51.0958C48.85 48.1386 52.131 43.9482 53.9745 39.0814C55.818 34.2146 56.1367 28.9021 54.8882 23.8498C53.6397 18.7976 50.8832 14.245 46.9851 10.797C43.0869 7.3491 38.2317 5.16919 33.0648 4.54704C27.8979 3.92489 22.6641 4.88996 18.0589 7.31399C13.4536 9.73802 9.69522 13.5062 7.28314 18.1177C5.51888 21.4906 4.53601 25.1978 4.38399 28.9669C4.36882 29.3431 4.22855 29.6822 4.03895 29.913C3.86068 30.13 3.67764 30.2135 3.51278 30.2339C3.31272 30.2587 3.15782 30.1953 3.07347 30.1269C3.03408 30.0949 3.01773 30.0685 3.0117 30.0562C3.00782 30.0484 2.99999 30.0318 3 29.9895C3.00028 29.2816 3.0284 28.5749 3.08408 27.871C3.36962 24.2614 4.37988 20.7271 6.07523 17.4858C8.61558 12.6292 12.5738 8.66064 17.4239 6.10773C22.274 3.55481 27.7862 2.53843 33.2278 3.19366C38.6694 3.84889 43.7828 6.14471 47.8882 9.77597C51.9936 13.4072 54.8967 18.2019 56.2116 23.5228C57.5264 28.8437 57.1908 34.4387 55.2492 39.5643C53.3077 44.6898 49.8523 49.1031 45.3422 52.2175C42.3323 54.296 38.9478 55.7303 35.4 56.4545C34.7074 56.5959 34.0086 56.7102 33.3051 56.797C33.2633 56.8021 33.2458 56.7964 33.2376 56.7935C33.2247 56.789 33.1965 56.776 33.16 56.7409C33.0818 56.6656 33 56.5198 33 56.3184C33 56.1523 33.0605 55.9604 33.2541 55.7568C33.46 55.5403 33.7796 55.3595 34.1513 55.2985Z" fill="#292C33" stroke={variants[variant]} strokeWidth="6"/>
    </svg>
  </span>
);

export default Loader;
