import EventEmitter from "src/shared/EventEmitter";
import { I18N_EVENT } from "./constants";

export type I18nDebug = "off" | "on" | "combine";

const emitter = new EventEmitter();
export let debug: I18nDebug = "off";

export const toggleDebug = () => {
  if (debug === "off") debug = "on";
  else if (debug === "on") debug = "combine";
  else if (debug === "combine") debug = "off";
  emitter.emit(I18N_EVENT, { debug });
};

export const isDev = import.meta.env.MODE === 'development';

export const getDebug = () => debug;

export const onChangeDebug = (cb: (data: { debug: I18nDebug }) => any) => emitter.on(I18N_EVENT, cb);